<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Mark Quote As Awarded</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-combobox
                        v-model="selectedProject"
                        :items="projects"
                        item-text="name"
                        hide-details
                        prepend-icon="mdi-account-hard-hat"
                        prefix="Project: "
                    >
                    </v-combobox>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="primary"
                class="mb-4"
                text
                :loading="loading"
                @click="openProjectDialog"
                v-if="user.permissions.includes('createProjects')"
            >
                CREATE NEW PROJECT
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                rounded
                :loading="loading"
                :disabled="!selectedProject"
                @click="awardedInProject"
            >
                SAVE
            </v-btn>
        </v-card-actions>
        <!-- project form -->
        <v-dialog
            :retain-focus="false"
            v-model="projectDialog"
            persistent
            max-width="640px"
        >
            <ProjectForm
                v-if="projectDialog"
                :quotes="[quote]"
                :users="users"
                :clients="clients"
                :selectedQuote="quote"
                :markQuoteAwardedProcess="true"
                :createForm="true"
                @closeDialog="projectDialog = false"
                @projectFromQuote="projectFromQuote"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'WorkOrderForm',
    components: {
        ProjectForm: () => import('@/components/Projects/ProjectForm.vue'),
    },
    props: {
        quote: {
            type: Object,
            deafult: () => {},
        },
    },
    data: () => ({
        loading: false,
        projects: [],
        selectedProject: null,
        projectDialog: false,
        users: [],
        user: {},
        clients: [],
    }),
    async mounted() {
        try {
            this.loading = true
            this.projects = await API.getLiteProjects({})
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.projectDialog = false
            this.$emit('close')
        },
        openProjectDialog() {
            this.projectDialog = true
        },
        async projectFromQuote(projectParams) {
            try {
                this.loading = true
                await API.projectFromQuote({
                    quoteId: this.quote.id,
                    ...projectParams,
                })
                this.$emit('updateChanges', this.quote)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async awardedInProject() {
            try {
                this.loading = true
                await API.awardedInProject({
                    quoteId: this.quote.id,
                    projectId: this.selectedProject.id,
                })
                this.$emit('updateChanges', this.quote)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
